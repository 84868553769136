import React from 'react';
import styled from 'styled-components';

import Logo from '@/components/logo';
import '@/stylesheets/index';
import { media } from '@/utils';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: calc(100vh - 8em);
  justify-content: center;

  svg {
    ${media.phone`
      width: 70vw;
    `}
  }
`;

const Grid = styled.div`
  bottom: 0;
  height: 100vh;
  opacity: .25;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  width: 100vw;
  z-index: 10;

  &::after {
    animation: scrollingGrid 5s linear;
    animation-iteration-count: infinite;
    background-image:
      linear-gradient(rgba(255, 255, 255, .5) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, .5) 1px, transparent 1px);
    background-size: 3% 3%, 4% 3%;
    background-position: 0 0, 0 0;
    bottom: -100vh;
    content: '';
    left: -50vw;
    mask-image: linear-gradient(to bottom, transparent 35%, white);
    position: absolute;
    top: -50vh;
    right: -50vw;
    transform: perspective(300px) rotateX(80deg);
    will-change: transform;

    @supports (mix-blend-mode: overlay) {
      background-image:
        linear-gradient(white 1px, transparent 1px),
        linear-gradient(90deg, white 1px, transparent 1px);
      mix-blend-mode: overlay;
    }
  }
`;

const IndexPage = () => {
  return (
    <Container>
      <Grid />
      <Logo />
    </Container>
  );
};

export default IndexPage;
